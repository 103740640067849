import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { Indent } from "~/content/styledComponents";

const Page = () => {
  return (
      <LessonLayout
        current_step={1}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 1</H2>

          <p>The internet can be a great source of information when you want to learn about new topics.</p>

          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_1_step_1.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <p>Think about the following scenario:</p>
          <p>
            <Indent>
              Some people suffer from long-term pain that affects their lives. Sometimes, the pain relievers that they can buy at the pharmacy or drug store do not help them much.
            </Indent>
          </p>
          <p>You want to find out if CBD use is supported by medicine and science to help people deal with chronic pain.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
